<template lang="html">

  <section class="collections page-doc">
    <hero-global img="/img/hero/colecciones.svg" title="Colecciones" />
    <div class="box-content container">
      <ul class="menu-list container">
        <li v-for="(collection, key) in Object.values(store.collections).slice(0,10)" :key="key" :value="key">
          <router-link :class="[cat == collection.slug ? 'router-link-active router-link-exact-active' : '']" :to="{
                  name: 'colecciones',
                  params: {
                    id: collection.slug
                  }
                }" :title="'Ver ' +collection.name" :alt="'Ver' +collection.name">
            {{ collection.name }}
          </router-link>
        </li>
      </ul>

      <div class="content">
        <div class="container view-document content" v-if="store.collections[this.cat].documentsId[this.doc]">
          <router-link :to="{
                  name: 'colecciones',
                  params: {
                    id: cat
                  }
                }" class="btn-back" title="Volver a la página anterior">
            Volver
          </router-link>
          <img :src="store.collections[this.cat].documentsId[this.doc].image.url" :alt="store.collections[this.cat].documentsId[this.doc].name">
          <div class="box-links-content">
            <p class="title">{{store.collections[this.cat].documentsId[this.doc].name}}</p>
            <p class="date">{{ currentDateTime(store.collections[this.cat].documentsId[this.doc].publish_date)}}</p>
            <p class="subtitle">{{store.collections[this.cat].documentsId[this.doc].author}}</p>
            <p v-if="store.collections[this.cat].documentsId[this.doc].description">{{store.collections[this.cat].documentsId[this.doc].description}}</p>
            <div class="container-links" v-if="store.collections[this.cat].documentsId[this.doc].file && Object.values(store.collections[this.cat].documentsId[this.doc].file).length > 0">
              <a v-for="(el, index) in  store.collections[this.cat].documentsId[this.doc].file" :key="index" :href="el.url" target="_blank" download="" title="Descargar archivo en .pdf" class="btn-white">
                <span class="nopaddingleft" v-if="el.type.replace(el.type.substr(0, (el.type.indexOf('/') + 1)), '.') == '.epub+zip'">Descargar {{el.type.replace(el.type.substr(0,(el.type.indexOf('/') + 1)),'.').slice(0, -4).trim()}}
                </span>
                <span v-else>
                  Descargar {{ el.type.replace(el.type.substr(0, (el.type.indexOf('/') + 1)), '.') }}
                </span>
              </a>

            </div>
          </div>



        </div>
      </div>
    </div>
    <other-links-interest class="links-interest-box container" />
  </section>

</template>

<script lang="js">
  import HeroGlobal from "@/components/Global/HeroGlobal";
  import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";
  import Carrousel2 from "@/components/Global/Carrousel2";
  import moment from 'moment'
  import { contentStore } from '@/stores/contents'
  export default {
    name: 'collections',
    props: ['cat', 'doc'],
    components: {
      OtherLinksInterest,
      HeroGlobal
    },
    setup() {
      const store = contentStore();
      store.loadCollections()
      return {
        store
      }
    },
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      currentDateTime(date) {
        return moment(date).locale('es').format('L')
      },
    },
    computed: {
      dataCollection() {
        if (this.store.collections[this.cat]) {
          var arr = Object.values(this.store.collections[this.cat].documentsId)
          console.log(arr)
          return arr.sort((a, b) => moment(a.publish_date).unix() - moment(b.publish_date).unix())
        }
        return null
      },
    },
    watch: {
     
    }
  }


</script>

